<template>
    <div class="article-layout">
        <div class="wrapper">
            <main>
                <section class="article-section">
                    <router-view></router-view>
                </section>
            </main>
            <aside>
                <ul class="articles-items">
                    <li  
                        v-for="article in articles"
                        :key="article?.id"
                        class="articles-item">
                        <img :src="article?.image" alt="">
                        <div class="articles-description">
                            <h3 class="articles-title"><router-link :to="'/article/' + article?.id">{{article?.title}}</router-link></h3>
                            <p class="articles-anons">{{article?.annonce}}</p>
                        </div>
                    </li>
                </ul>
            </aside>
        </div>
    </div>
</template>

<script>
export default {
    computed: {
        articles() {
            return this.$store.getters.getArticles.slice(0, 4)
        },
    }
}
</script>

<style scoped>

.article-layout .wrapper{
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}

.article-layout main{
    width: 67%;
}


.Breadcrumbs{
    display: flex;
    align-items: center;
}

.article-section {
    width: 100%;
}


/* aside */

aside {
    width: 25%;
}

aside .articles-items{
    list-style: none;
    padding: 0;
    margin: 0;
}

aside .articles-item{
    width: 100%;
    max-width: 250px;
    height: 338px;
    border-radius: 15px;
    background: #F7F7F5;
    padding: 20px 0;
    margin-bottom: 24px;
}

aside .articles-title{
    margin: 0;
}

aside .articles-title a{
    font-family: "Manrope SemiBold", "Arial", sans-serif;
    font-size: 14px;
    line-height: 19px;
    letter-spacing: -0.02em;
    display: inline-block;
    margin: 10px 7px;
    text-decoration: none;
    color: #000;
}


aside .articles-item img{
    width: 250px;
    height: 138px;
    object-fit: cover;
    display: block
}

aside .articles-item p{
    margin: 0 7px;
    font-size: 12px;
    line-height: 16px;
    color: #81859C;
}


@media(max-width: 1439px){
    aside{
        display: none;
    }

    .article-layout main {
        width: 100%;
    }
}
</style>