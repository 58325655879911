<template>
    <main>
        <div class="wrapper">
            
        </div>
        <section class="main-section">
            <div class="wrapper">
                <img class="elem star1" src="@/assets/images/icons/star.svg" alt="">
                <img class="elem star2" src="@/assets/images/icons/star.svg" alt="">
                <img class="elem ellipse1" src="@/assets/images/icons/ellipse.svg" alt="">
                <img class="elem ellipse2" src="@/assets/images/icons/ellipse.svg" alt="">
                <img class="elem ellipse3" src="@/assets/images/icons/ellipse.svg" alt="">
                <header class="main-section__header">
                    <h1><span>Решайся <img class="vector" src="@/assets/images/icons/main-vector.svg" alt=""></span> - только ты выбираешь, кем
                        тебе стать!</h1>
                    <p>Мы понимаем, как это сложно – определиться с будущей профессией, поэтому хотим помочь тебе сделать
                        выбор, исходя из твоих увлечений и навыков.</p>
                </header>
                <img class="main-section__image" src="@/assets/images/people.png" alt="">
            </div>
        </section>
        <section class="to-help">
            <div class="wrapper">
                <header class="to-help__header">
                <h2 class="to-help__title">Мы хотим помочь
                    <img src="@/assets/images/icons/main-vector2.svg" alt="">
                </h2>
                </header>
                <ul class="to-help__items">
                    <li class="to-help__item">
                        <div class="to-help__icon">
                            <img src="@/assets/images/icons/to-help-icon1.svg" alt="">
                        </div>
                        <h3>Учащимся</h3>
                        <p>выбрать подходящую профессию по душе и разглядеть свой потенциал</p>
                    </li>
                    <li class="to-help__item">
                        <div class="to-help__icon">
                            <img src="@/assets/images/icons/to-help-icon2.svg" alt="">
                        </div>
                        <h3>Родителям</h3>
                        <p>понять желания детей и научиться принимать и поддерживать их выбор</p>
                    </li>
                    <li class="to-help__item">
                        <div class="to-help__icon">
                            <img src="@/assets/images/icons/to-help-icon3.svg" alt="">
                        </div>
                        <h3>Педагогам</h3>
                        <p>организовать качественную профориентацию обучающихся, учитывая их индивидуальные особенности</p>
                    </li>
                </ul>
            </div>
        </section>

        <section class="articles">
            <div class="wrapper">

                <header class="articles-header">
                    <h2>Статьи</h2>
                    <img src="@/assets/images/icons/main-vector2.svg" alt="">
                </header>

                <div class="article-last">
                    <img class="article-last__img" :src="articles[0]?.image" alt="">
                    <p class="articles-logo">INformatio</p>
                    <div class="article-last__description">
                        <h3 class="articles-title"><router-link :to="'/article/' + articles[0]?.id">{{articles[0]?.title}}</router-link></h3>
                        <p class="article-hashtag">#{{articles[0]?.tags[0]}}</p>
                        <p class="articles-anons">{{articles[0]?.annonce}}</p>
                        <p class="article-views">
                            <img src="@/assets/images/icons/eye.svg" class="views-icon">
                            <span>{{articles[0]?.article_views}}</span>
                        </p>
                    </div>
                </div>

                <ul class="articles-items">
                    <ArticleComponent 
                        v-for="article in slicedArticlesArray"
                        :key="article.id"
                        :article="article"
                    />
                </ul>
            </div>
        </section>

        <section class="partners">
            <div class="wrapper">

                <header class="partners-header">
                    <h2>Партнеры</h2>
                    <img src="@/assets/images/icons/main-vector2.svg" alt="">
                </header>
                <ul class="partners-items">
                    <li class="partners-item">
                        <a href="https://expovision.tech/">
                            <div class="partners-logo">
                                <img src="@/assets/images/icons/expo.svg" alt="">
                            </div>
                            <p>Международная IT-компания Expovision</p>
                        </a>
                    </li>
                    <li class="partners-item">
                        <a href="https://rctt.dod95.ru/">
                            <div class="partners-logo">
                                <img src="@/assets/images/icons/quant.svg" alt="">
                            </div>
                            <p>Региональный комплекс общего и дополнительного образования "Квант"</p>
                        </a>
                    </li>
                    <li class="partners-item">
                        <a href="https://mon95.ru/">
                            <div class="partners-logo">
                                <img src="@/assets/images/icons/minobr.svg" alt="">
                            </div>
                            <p>Министерство образования<br> и науки Чеченской<br> Республики</p>
                        </a>
                    </li>
                    <li class="partners-item">
                        <a href="https://www.chesu.ru/section?p=298">
                            <div class="partners-logo">
                                <img src="@/assets/images/icons/clinic.svg" alt="">
                            </div>
                            <p>Юридическая клиника чеченского государственного университета</p>
                        </a>
                    </li>
                </ul>
            </div>
        </section>
    </main>
</template>

<script>
import ArticleComponent from "@/components/ArticleComponent.vue"

export default {
    name: "HomeView",
    components: { ArticleComponent },
    computed: {
        slicedArticlesArray() {
           /*  return this.articles.length > 3 ? this.articles.slice(1, 4) : this.articles */
            return this.articles.slice(1, ) 
        },
        articles() {
            return this.$store.getters.getArticles
        },
    }
}
</script>

<style scoped src='./style.css'></style>
<style scoped src='./adaptive.css'></style>