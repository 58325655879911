<template>
    <h1 class="article-title"><span>
        {{article?.title}}
        <img src="@/assets/images/icons/articles-vector.svg" alt="">
        </span>
    </h1>
    <p class="article-views">
        <img src="@/assets/images/icons/eye.svg" class="views-icon">
        <span>{{article?.article_views}}</span>
    </p>
    <img :src="article?.image" alt="" class="article-img">
    <p class="article-anons">{{article?.annonce}}</p>

    <div class="article-text" v-html="article?.content"></div>
    <div class="article-author">
        <p>Автор: {{article?.author.first_name}} {{article?.author.last_name}}</p>
        <p>{{article?.author.specialization}}</p>
    </div>
</template>

<script>
import { computed, onMounted } from 'vue'
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'

export default {
    setup() {
        const store = useStore()
        const route = useRoute()

        const articleId = route.params.id

        const article = computed(() => {
            return store.getters.getArticleById(articleId)
        })

        onMounted(() => {
            store.dispatch('viewArticle', {id: route.params.id})
        })

        return {
            article
        }
    }
}
</script>

<style scoped src='./style.css'></style>