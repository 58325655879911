<template>
  <div class="profession-item">
        <h3 class="profession-item__title"><span>{{title}}<img src="@/assets/images/icons/students-vector.svg" alt=""></span></h3>
        <ul class="profession-list">
            <ProfessionComponent
                v-for="profession in professions"
                :key="profession.id" 
                :profession="profession"
            />
        </ul>
    </div>
</template>

<script>
import ProfessionComponent from '@/components/ProfessionComponent.vue'
export default {
    props: {
        title: String,
        professions: Array,
    },
    components: { ProfessionComponent }
}
</script>

<style>

.title-vector{
    width: 320px;
}

.profession-items{
    list-style: none;
    padding: 0;
    margin: 0;
}

.profession-item__title{
    font-family: "Manrope SemiBold", "Arial", sans-serif;
    font-size: 36px;
    letter-spacing: -0.02em;
    margin: 0 0 70px 0;
}

.profession-item__title span{
    position: relative;
}

.profession-item__title img{
    position: absolute;
    width: 120%;
    bottom: 4px;
    left: 0;
    z-index: -1;
}

.profession-list{
    margin: 0 0 120px 0;
    padding: 0;
    border: 1px solid black;
    border-radius: 20px;
    font-size: 16px;
    font-family: "Manrope";
}

.profession-list header{
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.profession-list > div{
    padding: 24px 27px;
    border-bottom: 1px solid #000;
    margin: 0;
}

.profession-list > div:last-child{
    border-bottom: none;
}

.profession-list h4{
    font-size: 22px;
    font-family: "Manrope SemiBold", "Arial", sans-serif;
    color: #010101;
    margin: 0;
}

.profession-list > div{
    padding-bottom: 30px;
}

.profession-list p{
    margin: 0;
    margin-top: 30px;
    font-size: 16px;
    line-height: 24px;
}


@media(max-width: 768px) {
    .title-vector{
        width: 142px;
        /* right: ; */
    }
    
    .profession-item__title{
        font-size: 20px;
    }

    .profession-list{
        font-size: 12px;
    }

    .profession-list h4{
        font-size: 14px;
        line-height: 27px;
    }

    .profession-list p{
        font-size: 12px;
        line-height: 17px;
    }
}


</style>