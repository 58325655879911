import { createStore } from "vuex";
import axios from "axios";

export default createStore({
  state: {
    articles: [],
    authors: []
  },
  getters: {
    getArticles: state => state.articles,
    getArticleById: state => id => state.articles.find(article => article.id == id),
    getAuthors: state => state.authors
  },
  mutations: {
    setArticles(state, payload) {
      state.articles = payload.articles
      console.log(state.articles)
    },
    setAuthors(state, payload) {
      state.authors = payload.authors.filter(author => author.is_in_contact === true)
    },
  },
  actions: {
    async fetchArticles({commit}) {
      try{
        const res = await axios.get('https://niysaharzhar.pythonanywhere.com/articles/');
        commit('setArticles', {articles: res.data})
      } catch {
        throw new Error()
      }
    },
    async fetchAuthors({commit}) {
      try{
        const res = await axios.get('https://niysaharzhar.pythonanywhere.com/profiles/');
        commit('setAuthors', {authors: res.data});
      } catch {
        throw new Error()
      }
    },
    async viewArticle({dispatch}, payload) {
      try{
        await axios.get(`https://niysaharzhar.pythonanywhere.com/articles/${payload.id}/`);
        dispatch('fetchArticles')
      } catch {
        console.log('add view get request')
      }
    }
  },
  modules: {},
});
