<template>
    <header class="main-header">
        <div class="wrapper">
         <div class="backdrop" :class="{'backdrop__active': isActive}"></div>
         <div class="logos">
            <router-link to="/"><img src="@/assets/images/icons/informatio.svg" alt=""></router-link>
            <a href="https://xn--80afcdbalict6afooklqi5o.xn--p1ai/" target="_blank"><img src="@/assets/images/icons/fond.svg" alt=""></a>
         </div>
         <div class="burger" @click="toggleBurger">
             <button class="menu-btn" :class="{'menu-btn_active': isActive}">
                 <span></span>
             </button>
         </div>
         <nav class="main-nav" :class="{'main-nav__active': isActive}">
             <div class="mob-menu">
                 <h4>Меню</h4>
             </div>

             <MainNavListItems @click="toggleBurger"/>
            <!--  <ul class="main-nav__items" @click="toggleBurger">
                 <li class="main-nav__item nav-item__active"><router-link to="/">Главная</router-link> </li>
                 <li class="main-nav__item"><router-link to="/about-project">О нас</router-link></li>
                 <li class="main-nav__item"><router-link to="/professions">Учащимся</router-link></li>
                 <li class="main-nav__item"><router-link to="/toparents">Родителям</router-link></li>
                 <li class="main-nav__item"><router-link to="/toteachers">Педагогам</router-link></li>
             </ul> -->
         </nav>
        </div>
     </header>
 </template>
 
 <script>
 import MainNavListItems from '@/components/MainNavListItems.vue'

 export default {
    components: {
        MainNavListItems
    },
    data() {
        return {
            isActive: false
        }
    },
    methods: {
        toggleBurger() {
            this.isActive = !this.isActive
        }
    }
 }
 </script>
 
 <style lang="scss" scoped>
 .main-header{
     width: 100%;
     padding: 30px 0;

     .wrapper{
        
        display: flex;
        align-items: center;
        justify-content: space-between;
     }
 }
 
 .logos{
     display: flex;
     align-items: center;
 
     img:first-child{
         margin-right: 32px;
     }
 }
 .main-nav{
 
     &__items{
         list-style: none;
         display: flex;
         justify-content: flex-end;
         padding: 0;
     }
 
     &__item{
         padding: 0;
         margin: 0;
 
         a{
             text-decoration: none;
             font-family: 'Manrope Medium';
             color: #000;
             font-size: 20px;
             margin-left: 32px;
 
             &:hover,
             &:active{
                 color: #14BEB6;
             }
         }
     }
 
     &:active a{
         color: #14BEB6;
     }
 }
 
 .main-nav__item a:hover,
 .main-nav__item a:active{
     color: #14BEB6;
 }
 
 /* стили гамбургера */
 .burger{
     position: relative;
     z-index: 2001;
     display: none;
     justify-content: center;
     align-items: center;
     transition: transform .5s .5s, opacity .5s;
 
     &_active{
         transition: transform .5s .5s, opacity .5s;
         background-color: rgb(114, 114, 114);
         border-radius: 50%;
     }
 }
 
 .menu-btn{
     display: block;
     width: 45px;
     height: 45px;
     border-radius: 50%;
     position: relative;
     background: none;
     border: none;
     
     span,
     span::before,
     span::after {
         position: absolute;
         top: 50%; margin-top: -1px;
         left: 50%; margin-left: -12.5px;
         width: 25px;
         height: 4px;
         background: #111;
     }
 
     span::before,
     span::after {
         content: '';
         display: block;
         transition: 0.5s;
     }
     span::before{
         transform: translateY(-7.2px);
     }
 
     span::after{
         transform: translateY(5px);
     }
 
     &_active{
         span{
             height: 0;
 
             &:before{
                 transform: rotate(45deg);
             }
 
             &:after{
                 transform: rotate(-45deg);
             }
         } 
     }
 }
 
 
 .mob-menu { 
    display: none;
 }
 .backdrop{
    display: none;
    position: fixed;
    right: 0;
    left: 0;
    top: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 99;
 }
 
 @media(max-width: 1439px) {

     .main-nav__item a {
         font-size: 18px;
         margin-left: 20px;
     }
 }
 
 
 
 @media(max-width: 1023px) {
     .main-nav__item a {
         font-size: 15px;
     }
 
     .logos a{
         width: 50%;
     }

     .logos a img{
         max-width: 100%;
         height: 60px;
     }

     .logos img:first-child{
        margin-right: 0;
     }
 }
 
 
 
 @media(max-width: 935px) {
     .backdrop__active{
         display: block;
     }
 
     .burger{
         display: flex;
     }
 
     .main-nav {
         position: absolute;
         display: none;
         width: 100vw;
         height: 667px;
         top: 0;
         left: 0;
         background-color: #fff;
         z-index: 100;
         padding: 32px 23px 32px 32px;
     }
 
     .main-nav__active{
         display: block;
     }
 
     .main-nav__items{
         flex-direction: column;
         margin: 0;
         padding: 0;
     }
 
     .main-nav__item{
         margin-bottom: 27px;
         a{
             font-size: 18px;
             margin: 0;
         }
     }
 
 
     .mob-menu{
         display: flex;
         justify-content: space-between;
         align-items: flex-start;
         
         h4{
             font-size: 24px;
             font-family: "Manrope Bold";
             margin: 0 0 48px 0;
         }
 
         div{
             background-color: #F5F5F5;
             border-radius: 50%;
             width: 34px;
             height: 34px;
             display: flex;
             justify-content: center;
             align-items: center;
             cursor: pointer;
         }
     }
 }
 </style>