<template>
    <main>
        <div class="wrapper">
            <h1>Контакты специалистов</h1>
            <section>
                <table class="specialists-table">
                    <thead>
                        <th>Специалист</th>
                        <th>Направление</th>
                        <th>E-mail</th>
                    </thead>
                    <tbody>
                        <tr
                            v-for="(author, index) in authors"
                            :key="index"
                        >
                            <td class="specialists-table__author">{{author.last_name}} {{author.first_name}}</td>
                            <td>{{author.specialization}}</td>
                            <td>{{author.email}}</td>
                        </tr>
                    </tbody>
                </table>
            </section>
        </div>
    </main>
</template>

<script>
export default {
    beforeMount() {
        this.$store.dispatch('fetchAuthors')
    },
    computed: {
        authors() {
            return this.$store.getters.getAuthors
        }
    }
}
</script>

<style scoped lang="scss">
    .specialists-table {
        width: 100%;
        padding: 0;
        border-collapse: collapse;
        font-family: "Manrope";

        thead{
            background-color: #1C242B;
            color: #DDD;

            font-family: "Manrope Bold";
        }

        tr{
            border-top: 1px solid #14BEB6;
            border-bottom: 1px solid #14BEB6;
        }

        th, td {
            padding: 1.5em 1em;
        }

        &__author {
            font-family: "Manrope Bold";
        }
    }
</style>