export default [
    {
        id: 1,
        title: 'Медицина',
        professions: [
            {
                id: 1, 
                name: 'Невролог', 
                definition: 'врач, который проводит диагностику, профилактику и лечение заболеваний центральной и периферической нервной системы, а также опорно-двигательного аппарата.', 
                blocks: [
                    {
                        title: 'Что лечит невролог?', 
                        items: [
                            'Боли в спине и шее',
                            'Боли в голове и лице',
                            'Головокружения',
                            'Слабость в конечностях',
                            'Патологии нервной системы',
                            'Заболевания внутренних органов',
                        ]
                    },
                    {
                        title: 'Заболевания головного мозга:', 
                        items: [
                            'Инсульт',
                            'Эпилепсия',
                            'Болезнь Альцгеймера',
                            'Болезнь Паркинсона',
                        ]
                    },
                    {
                        title: 'Последствия психоэмоционального перенапряжения:', 
                        items: [
                            'Нарушения сна',
                            'Артериальная гипертензия',
                        ]
                    },
                    {
                        title: 'Что должен знать невролог?', 
                        items: [
                            'Особенности строения и функционирования нервной системы',
                            'Природу и причины неврологических заболеваний, их влияние на другие органы и системы организма',
                            'Клинические проявления болезней головного и спинного мозга, поражений периферических нервов',
                            'Порядок проведения оценки неврологического статуса',
                            'Инструментальные способы обследования нервной системы',
                            'Лечебные методики, существующие в неврологии',
                            'Клинические проявления, которые являются показанием к госпитализации или оперативному лечению',
                            'Симптомы осложнений, побочные эффекты фармакологических средств',
                            'Правила расшифровки рентгеновских снимков, КТ, МРТ',
                            'Перечень рекомендаций по реабилитации неврологических больных',
                            'Правила ведения документооборота',
                        ]
                    },
                    {
                        title: 'Где обучаться?', 
                        text: '',
                        items: [
                            'Работать врачом-неврологом может человек, который окончил специалитет по специальности «Лечебное дело» или «Педиатрия», а затем ординатуру по направлению «Неврология» или ординатуру по одной из специальностей: «Общая врачебная практика (семейная медицина)», «Педиатрия", «Терапия» и дополнительное профессиональное образование - программы профессиональной переподготовки по специальности «Ревматология».',
                            'В Медицинском институте Чеченского государственного университета им. А.А. Кадырова имеются программы специалитета <a href="https://www.chesu.ru/major?p=43">«Лечебное дело»</a> и <a href="https://www.chesu.ru/major?p=97">«Педиатрия»</a>',
                            'Также в ординатуре МИ ЧГУ обучают по программе <a href="https://www.chesu.ru/major?p=272">«Неврология»</a>'
                        ]
                    },
                ]
            },
            {
                id: 2, 
                name: 'Аллерголог-иммунолог', 
                definition: 'это врач, задачами которого являются диагностика, лечение и профилактика различных аллергических заболеваний и нарушений в работе иммунной системе.', 
                blocks: [
                    {
                        title: 'Что лечит аллерголог-иммунолог?', 
                        items: [
                            'поллинозы, аллергический ринит, аллергический конъюнктивит',
                            'различные аллергодерматиты, крапивница',
                            'бронхиальная астма, атопический дерматит',
                            'пищевая и лекарственная аллергия',
                            'ангионевротический отек кожи и слизистых оболочек (отек Квинке)',
                            'хроническая экзема и другие кожные заболевания, которые не • • поддаются лечению у дерматолога',
                            'врожденные и приобретенные иммунодефициты (в том числе, ВИЧ-инфекция) или подозрение на них',
                            'аутоиммунные заболевания и други',
                        ]
                    },
                    {
                        title: 'Что должен знать аллерголог-иммунолог?', 
                        items: [
                            'особенности иммунной системы человека, ее возрастные изменения',
                            'нарушения в работе иммунной системы (иммунодефициты, аутоимунные заболевания, реакции гиперчувствительности)',                            
                            'причины возникновения аллергической реакции и иммуннодефицитных состояний, интерпретация и анализ лабораторных и инструментальных методов диагностики',                            
                            'проведение обследования пациентов в целях выявления аллергологических и иммунологических заболеваний',                            
                            'как проводить специальные аллергологические пробы, исследовать функцию внешнего дыхания',                            
                            'методы предотвращения и контроля распространения инфекционных заболеваний',                            
                            'своевременное распознавание и оказание неотложной помощи при острых аллергических реакциях по типу крапивницы и отека Квинке',                            
                            'ведение медицинской документации в электронном и бумажном формате, согласно действующим стандартам',                            
                            'как разрабатывать план лечения пациентов с аллергологическими и иммунологическими заболеваниями, контролировать его эффективность и безопасность',                            
                            'проведение и контроль реабилитации пациентов, в том числе определять медицинские показания для направления пациентов на санитарно-курортное лечение'
                        ]
                    },
                    {
                        title: 'Где обучаться?', 
                        text: '',
                        items: [
                            'Работать аллергологом-иммунологом может человек, который окончил специалитет по специальности «Лечебное дело» или «Педиатрия», а затем ординатуру по направлению «Аллергология и иммунология» или ординатуру по одной из специальностей: «Общая врачебная практика (семейная медицина)», «Педиатрия», «Терапия» и профессиональную переподготовку по специальности «Аллергология и иммунология».',
                            'В Медицинском институте Чеченского государственного университета им. А.А. Кадырова имеются программы специалитета <a href="https://www.chesu.ru/major?p=43">«Лечебное дело»</a> и <a href="https://www.chesu.ru/major?p=97">«Педиатрия»</a>.',
                            'Российский национальный исследовательский медицинский университет имени Н. И. Пирогова предлагает обучение по программе ординатуры <a href="https://rsmu.ru/academics/clinical-residency/obrazovatelnye-programmy-i-uchebnye-plany-ordinatury/310826-allergologija-i-immunologija-fgos-2022/">«Аллергология и иммунология»</a>'
                        ]
                    },
                ]
            },
            {
                id: 3, 
                name: 'Ревматолог', 
                definition: 'это врач, который занимается диагностикой и последующим лечением заболеваний суставов и соединительных тканей. Также в компетенцию врача входит лечение ревматических пороков сердца.', 
                blocks: [
                    {
                        title: 'Что лечит ревматолог?', 
                        items: [
                            'заболевания соединительной ткани',
                            'болезни суставов (которые связаны с заболеваниями соединительной ткани)',
                            'ревматические пороки сердца',
                            'ревматоидный артрит',
                            'болезнь Бехтерева',
                            'острая ревматическая лихорадка',
                            'подагрический артрит',
                            'ревматизм',
                            'ювенильный (юношеский) ревматоидный артрит',
                            'системная склеродермия',
                            'приобретенные ревматические пороки сердца',
                            'феномен Рейно (поражение мелких артерий пальцев)',
                            'остеопороз',
                            'системная красная волчанка',
                            'бурсит (заболевание околосуставных мягких тканей)',
                            'системные васкулиты',
                        ]
                    },
                    {
                        title: 'Что должен знать ревматолог?', 
                        items: [
                            'анатомию опорно-двигательного аппарата, особенности функционирования',
                            'клинические проявления болезней суставов, соединительной ткани (боли, изменения в суставах, лихорадка неясного генеза)',
                            'анализировать результаты лабораторных (С-реактивный белок, ревматоидный фактор, мочевая кислота и т.д.) и инструментальных (рентгенография, ультразвуковое исследование, магнитно-резонансная томография, компьютерная томография) методов обследования',
                            'обосновать постановку ревматологического диагноза. Точный диагноз можно поставить после того, как набран определенный пул признаков конкретного заболевания',
                            'взаимодействовать со специалистами смежных направлений в медицине (терапевт, педиатр, инфекционист, эндокринолог)',
                            'особенности первичной и вторичной профилактики ревматологических заболеваний',
                            'высокоэффективные методы лечения, также поддержания периода ремиссии у пациентов с болезнями соединительной ткани',
                            'правила ведения медицинской документации',
                        ]
                    },
                    {
                        title: 'Где обучаться?', 
                        items: [                                    
                            'Работать ревматологом может человек, который окончил специалитет по специальности «Лечебное дело» или «Педиатрия», а затем ординатуру по направлению «Ревматология» или ординатуру по одной из специальностей: «Общая врачебная практика (семейная медицина)», «Педиатрия», «Терапия» и профессиональную переподготовку по специальности «Ревматология»',
                            'В Медицинском институте Чеченского государственного университета им. А.А. Кадырова имеются программы специалитета <a href="https://www.chesu.ru/major?p=43">«Лечебное дело»</a> и <a href="https://www.chesu.ru/major?p=97">«Педиатрия»</a>',
                            'Российский национальный исследовательский медицинский университет имени Н. И. Пирогова предлагает обучение по программе ординатуры <a href="https://rsmu.ru/academics/clinical-residency/obrazovatelnye-programmy-i-uchebnye-plany-ordinatury/310846-revmatologija/">«Ревматология»</a>',
                        ]
                    },
                ]
            },
            {
                id: 4, 
                name: 'Психотерапевт', 
                definition: 'Психотерапевт', 
                blocks: [
                    {
                        title: 'Что лечит психотерапевт?', 
                        items: [
                            'неврозы',
                            'выраженные формы проявления депрессий, обусловленные • различными причинами',
                            'страхи',
                            'апатия',
                            'перенесенные психические травмы (потеря близких, развод, • ситуация хронического стресса или затяжного конфликта)',
                            'чрезмерная тревожность',
                            'наличие определенного типа зависимостей (пища, наркотики, • алкоголь, азартные игры и пр.)',
                            'анорексия',
                            'булимия',
                            'паническая атака',
                            'фобии, панические состояния',
                            'синдром хронической усталости',
                            'семейные и личные проблемы, в том числе нестандартные, с которыми, зачастую, люди либо стесняются, либо боятся куда-то идти',
                        ]
                    },
                    {
                        title: 'Что должен уметь и знать психотерапевт?', 
                    },
                    {
                        title: 'Знать:', 
                        items: [
                            'причины возникновения психоневрологических заболеваний',
                            'основные методы психотерапии (групповая психотерапия, психоанализ, разговорная терапия и т.д.)',
                            'клиническую картину, диагностику, терапию и профилактику состояний, связанных с попыткой или угрозой суицида, неуправляемым психомоторным возбуждением',
                            'морально-этические нормы и правила в работе, врачебной этике, врачебной тайны',
                        ]
                    },
                    {
                        title: 'Уметь:', 
                        items: [
                            'определять личностные особенности пациента',
                            'оценивать соматическое и неврологическое состояние, а также возможные побочные эффекты и осложнения терапии психических и поведенческих расстройств',
                            'лечить психические расстройства и расстройства поведения, требующие использования специальных методов и сложных медицинских технологий',
                            'вести учетно-отчетную медицинскую документацию',
                        ]
                    },
                    {
                        title: 'Где обучаться?', 
                        items: [
                            'Работать психотерапевтом может человек, который окончил специалитет по специальности «Лечебное дело» или «Педиатрия», а затем ординатуру по направлению «Психиатрия»  или ординатуру по специальности «Психиатрия» и дополнительное профессиональное образование - программы профессиональной переподготовки по специальности «Психотерапия»',
                            'В Медицинском институте Чеченского государственного университета им. А.А. Кадырова имеются программы специалитета <a href="https://www.chesu.ru/major?p=43">«Лечебное дело»</a> и <a href="https://www.chesu.ru/major?p=97">«Педиатрия»</a>',
                            'Российский национальный исследовательский медицинский университет имени Н. И. Пирогова предлагает обучение по программе ординатуры <a href="https://rsmu.ru/academics/clinical-residency/obrazovatelnye-programmy-i-uchebnye-plany-ordinatury/310822-psikhoterapija/">«Психотерапия»</a>',
                        ]
                    },
                ]
            },
            {
                id: 5, 
                name: 'Детский эндокринолог', 
                definition: 'это врач, который занимается профилактикой, диагностикой и лечением заболеваний эндокринной системы. Это могут быть ожирение и мышечная слабость, проблемы с половой функцией и остеопороз, опухоли в железах внутренней секреции, а также другие заболевания, включая нервно-психические.', 
                blocks: [
                    {
                        title: 'Что лечит детский эндокринолог?', 
                        items: [
                            'ожирение разной степени',
                            'нарушение роста',
                            'нарушение полового развития',
                            'недостаточность надпочечников',
                            'болезнь Иценко-Кушинга',
                            'сахарный диабет',
                            'остеопороз',
                            'гипотириоз',
                            'аномалии физического развития',
                            'заболевания щитовидной железы и др',
                        ]
                    },
                    {
                        title: 'Что должен уметь и знать аллерголог-иммунолог?', 
                    },
                    {
                        title: 'Знать:', 
                        items: [
                            'анатомо-функциональное состояние детей с заболеваниями и (или) состояниями эндокринной системы',
                            'клиническую картину, особенности течения, осложнения и исходы заболеваний и (или) патологических состояний органов эндокринной системы',
                        ]
                    },
                    {
                        title: 'Уметь:', 
                        items: [
                            'собирать анамнез: подробный расспрос родителей об имеющихся наследственных заболеваниях и приобретенных болезнях эндокринной системы, течении беременности и родов, развитии ребенка и проблемах его здоровья. Анализ жалоб ребенка и замеченных родителями отклонений в его здоровье',
                            'проводить обследования детей в целях выявления заболеваний и (или) состояний эндокринной системы, в том числе и проведение специальных тестов для оценки нарушений углеводного обмена, функциональной активности коры надпочечников и т.д',
                            'интерпретировать и анализировать результаты осмотра, лабораторного исследования и инструментального обследования, постановка диагноза',
                            'разрабатывать план лечения, оценивать эффективность и безопасность применения лекарственных препаратов, медицинских изделий и лечебного питания',
                            'своевременно распознавать и оказывать неотложную помощь при гипергликемических и гипогликемических состояниях и других экстренных ситуациях',
                            'вести медицинскую документацию в электронном и бумажном формате, согласно действующим стандартам',
                            'проводить и контролировать реабилитацию пациентов',
                        ]
                    },
                    {
                        title: 'Где обучаться?', 
                        items: [
                            'Работать детским эндокринологом может человек, который окончил специалитет по специальности «Лечебное дело» или «Педиатрия», а затем ординатуру по направлению «Детская эндокринология»  или ординатуру по одной из специальностей: «Педиатрия», «Эндокринология» и дополнительное профессиональное образование - программы профессиональной переподготовки по специальности «Детская эндокринология»',
                            'В Медицинском институте Чеченского государственного университета им. А.А. Кадырова имеются программы специалитета <a href="https://www.chesu.ru/major?p=43">«Лечебное дело»</a> и <a href="https://www.chesu.ru/major?p=97">«Педиатрия»</a>',
                            'Российский национальный исследовательский медицинский университет имени Н. И. Пирогова предлагает обучение по программе ординатуры <a href="https://rsmu.ru/academics/clinical-residency/obrazovatelnye-programmy-i-uchebnye-plany-ordinatury/310817-detskaja-ehndokrinologija-fgos-2022/">«Детская эндокринология»</a>',
                        ]
                    },
                ]
            },
        ]
    },
    {
        id: 2,
        title: 'IT-сфера',
        professions: [
            {
                id: 1, 
                name: 'Fullstack-разработчик', 
                definition: '(или фулстек-разработчик) – это разработчик, который должен разбираться во всем стеке технологий и используемых в проекте компонентов, как в части фронтенда, так и бэкенда. При этом такому разработчику совсем не обязательно быть senior во всех технологиях, которые используются при разработке приложения.', 
                blocks: [
                    {
                        title: 'Fullstack разработчик должен:', 
                        items: [
                            'знать HTML/CSS',
                            'знать язык программирования JavaScript и один из языков программирования для бэкенда (Pytho, PHP, Java и т.д.',
                            'уметь работать с базами данных и менеджером пакетов',
                            'уметь выбирать стек разработки под реализацию конкретной задачи, выстраивать архитектуру приложения',
                            'Уметь работаь с HTTP, API, JSON и т.д.',
                            'Уметь работать с системами управления версиями (чаще всего Git)',
                        ]
                    },
                    {
                        title: 'Fullstack-разработчик', 
                        items: [
                            'создает backend и frontend продукта',
                            'выполняет проектирование и техническую поддержку проекта',
                            'консультирует других участников создания продукта',
                            'специалист уровня Senior или Team lead часто может общаться с заказчиком, но это связано с получением обратной связи по функциональности продукта, а не, например, по финансированию проекта',
                            'специалист с развитыми аналитическими способностями, которому надо уметь трудиться в режиме многозадачности. Он должен педантично подходить к поставленным задачам, уметь работать как самостоятельно, так и в команде',
                        ]
                    },
                    {
                        title: 'Обучение на fullstack-разработчика на JavaScript', 
                    },
                    {
                        title: 'Деятельности в сфере fullstack-разработки предшествует обучение, можно получить высшее техническое образование, например, на таких направлениях подготовки:', 
                        items: [
                            '«Программная инженерия» (код: 09.03.04)',
                            '«Прикладная информатика» (код: 09.03.03)',
                            '«Прикладная математика и информатика» (код: 01.03.02)',
                        ]
                    },
                    {
                        title: 'ВУЗы и СУЗы для fullstack-разработчика ', 
                        items: [
                            'МФТИ',
                            'НИЯУ МИФИ',
                            'МГТУ им. Н. Э. Баумана',
                            'РЭУ им. Г. В. Плеханова',
                            'ГГНТУ им. Акад. М. Д. Миллионщикова (<a href="https://ipit.gstou.ru/%D0%BD%D0%B0%D0%BF%D1%80%D0%B0%D0%B2%D0%BB%D0%B5%D0%BD%D0%B8%D1%8F-%D0%BF%D0%BE%D0%B4%D0%B3%D0%BE%D1%82%D0%BE%D0%B2%D0%BA%D0%B8">ИПИТ</a>, <a href="https://gstou.ru/structure/fspo.php">ФСПО)</a>',
                            '<a href="https://www.chesu.ru/bachelor">ЧГУ им. А.А. Кадырова</a>',
                            '<a href="https://grozny.ucheba.ru/uz/22591">Грозненский государственный колледж экономики и информационных технологий</a>',
                        ]
                    },
                ]
            },
            {
                id: 2, 
                name: 'Специалист по информационной безопасности (ИБ)', 
                definition: 'строит и внедряет систему защиты в ИТ-инфраструктуру компании или организации, предотвращает и блокирует попытки проникнуть в нее извне. Профессия на стыке программирования, аналитики, администрирования систем и консалтинга.', 
                blocks: [
                    {
                        title: 'Специализации в профессии: ', 
                        items: [
                            '<b>Инженер по безопасности</b> – это своеобразный провайдер в мир безопасности для всей компании. Он разрабатывает тренинги и рекомендации по вопросам безопасности для внутренних групп сотрудников, оценивает инструменты безопасности, оказывает помощь в подборе персонала, занимается административной работой в сфере защиты информации',
                            '<b>Специалист по кибербезопасности</b> – это гуру компьютеров, сетей и программного обеспечения. Он отвечает за то, чтобы средства программной защиты организации работали качественно и надежно',
                            '<b>Инженер по облачной безопасности</b> отлично разбирается в цифровых облачных платформах, специализируясь на защите баз данных, хранящихся на них',
                            '<b>Аудитор информационной безопасности</b> – это профи проверок различных систем, которые могут подвергаться атаке',
                        ]
                    },
                    {
                        title: 'Профессия специалиста по кибербезопасности предусматривает решение следующих основных задач:', 
                        items: [
                            'защита персональных данных, коммерческой тайны и другой закрытой информации',
                            'принятие мер по борьбе с хакерскими атаками',
                            'защита от хищений интеллектуальной собственности',
                            'поддержание информационной безопасности критически важных объектов: банки, предприятия, коммунальные службы, учреждения здравоохранения и т. д.',
                            'выявление уязвимостей в действующих сетях и их оперативное устранение',
                        ]
                    },
                    {
                        title: ' Специалист по информационной безопасности должен иметь навыки:', 
                        items: [
                            'поиска уязвимостей на клиентской части веб-приложений, эксплуатация клиентских уязвимостей, способы защиты',
                            'поиска server-side-уязвимостей, понимание особенностей Bug Bounty',
                            'взлома беспроводных сетей, устройство сетей и способы обеспечения безопасности в них',
                            'реверса приложений, поиска и эксплуатации бинарных уязвимостей. Основы криптографических протоколов',
                        ]
                    },
                    {
                        title: 'Специалисты по безопасности должны:', 
                        items: [
                            'владеть средствами, которые помогут им провести мониторинг безопасности IT-среды',
                            'организовать защиту против известных и вновь возникающих угроз',
                            'оперативно выявлять и сдерживать любые атаки',
                            'проводить восстановительные мероприятия',
                            'При этом они должны обеспечить свободный и безопасный доступ к корпоративной информации и ресурсам, организовав виртуальные частные сети, построив несколько уровней защиты, обеспечив отказоустойчивость, мониторинг и контроль',
                        ]
                    },
                    {
                        title: 'Где обучаться?', 
                        items: [
                            'ГГНТУ им. Акад. М. Д. Миллионщикова, ИПИТ, <a href="https://ipit.gstou.ru/09-03-02">БИС</a>',
                            '«Безопасность компьютерных систем (инновационные технологии компьютерной безопасности)» (МИФИ)',
                            '«Информационная безопасность автоматизированных систем критически важных объектов» (МГТУ им. Баумана, РГУНГ им. Губкина, ЮУрГУ, НГТУ, ЧелГУ)',
                        ]
                    },
                ]
            },
            {
                id: 3, 
                name: 'Python-разработчик', 
                definition: 'специалист, который применяет в работе многоуровневый язык программирования Python. Язык отличается минимальным набором символов и прост для изучения и использования относительно других языков. В силу универсальности позволяет выполнять различную работу: писать плагины и скрипты, разрабатывать игры, мобильные приложения, онлайн-сервисы, сопровождать ПО.', 
                blocks: [
                    {
                        title: 'Python-программист занимается следующим:', 
                        items: [
                            'Создаёт вычислительные программы',
                            'Работает с сетевыми протоколами HTTP и HTTPS',
                            'Пишет программное обеспечение (парсер) для сбора и структурирования данных',
                            'Пишет новые парсеры для сбора данных с сайтов',
                            'Администрирует работу парсеров',
                            'Правит написанные ранее парсеры при изменении данных',
                            'Настраивает отдачу данных в модуль машинного обучения',
                            'Создаёт многопоточные приложения',
                            'Использует систему контроля версий',
                        ]
                    },
                    {
                        title: 'Перечислим, что должен знать и уметь программист на Питоне:', 
                        items: [
                            'Понимать принципы объектно-ориентированного программирования и методологию разработки',
                            'Знать систему управления версиями Git',
                            'Программировать на Python, писать читаемый код',
                            'Пользоваться фреймворками Django и Flask, библиотеками, ПО для автоматизации развертывания приложений Docker',
                            'Работать с базами данных, алгоритмами и SQL',
                            'Тестировать проект на всех этапах разработки',
                            'Разбираться в верстке на HTML и CSS',
                            'Читать и понимать техническую документацию на английском языке',
                        ]
                    },
                    {
                        title: 'Python — универсальный язык программирования, но наиболее применим в следующих направлениях:', 
                        items: [
                            'Веб-разработка',
                            'Математические вычисления (анализ и визуализация данных)',
                            'Машинное обучение (Machine Learning)',
                            'Глубокое обучение (Deep Learning)',
                            'Администрирование ОС и локальных сетей',
                            'Автоматизация и написание скриптов и парсеров',

                        ]
                    },
                    {
                        title: 'Где обучаться?', 
                        items: [
                            '<a href="https://www.rea.ru/ru/org/faculties/fmesi/programms/Pages/2021/bak-prikladnaya-informatika-v-ehkonomike.aspx">Прикладная информатика</a>, бакалавриат, Институт математики, информационных систем и цифровой экономики РЭУ им. Г.В. Плеханова, РЭУ им. Г. В. Плеханова',
                            '<a href="https://isct.rosnou.ru/osnovnye-obrazovatelnye-programmy/bakalavriat">Математическое моделирование и вычислительная математика, бакалавриат, ИСИКТ, РосНОУ</a>',
                        ]
                    },
                ]
            },
            {
                id: 4, 
                name: 'UI/UX дизайнер', 
                definition: ' это специалист, который создает или развивает продукт так, чтобы он был максимально эффективным, решал главные проблемы и задачи пользователей.', 
                blocks: [
                    {
                        title: 'UI («User Interface»)', 
                        items: [
                            '«пользовательский интерфейс», а UX («user experience») — это «пользовательский опыт». UI-дизайнер отвечает за визуализацию приложения, делая его удобным и функциональным. Дабы продукт с комфортом воспринимался глазами пользователя, специалист UI отвечает за подбор форм, цветов и других параметров.',
                        ]
                    },
                    {
                        title: 'Что касается UX-дизайнера', 
                        items: [
                            'он в большей степени ответственен за функциональность дизайна. В итоге: приложением легко и удобно пользоваться.',
                        ]
                    },
                    {
                        title: 'Чем должен владеть и что должен знать UI/UX дизайнер?', 
                        items: [
                            'Базовые инструменты. Figma или Sketch, Adobe XD, Tilda, Readymag, Webflow',
                            'Дополнительные программы. Principal, After Effects, Cinema 4D, Blender',
                            'Анализ и проектирование. Понимать задачи бизнеса и помогать пользователям',
                            'Знание основ разработки интерфейсов для веб-сервисов и мобильных приложений',
                            'Знание психологии пользователей, особенностей взаимодействия людей с сервисами',
                            'Умение работать с инструментами для аналитики',
                            'Для сбора данных и аналитики: Google Analitycs 360, Google Firebase, OWOX BI, Amplitude, Customer Journey map, BPMN-диаграммы, опросы и интервью',
                            'Для структурирования: Miro, FlowMapp, MindMeister, XMind',
                        ]
                    },
                    {
                        title: 'Основные обязанности дизайнера в UX:', 
                        items: [
                            'проектирование пользовательских страниц и экранов',
                            'согласование графических элементов с фирменным стилем',
                            'отрисовка кнопок, блоков, слайдеров, переключателей',
                            'формирование руководства по созданию программного продукта',
                            'верстка',
                            'проведение пользовательских опросов',
                            'создание портрета ЦА',
                            'разработка рабочего прототипа продукта',
                            'создание логической цепочки использования продукта',
                            'тестирование, внесение правок/изменений по его результатам',
                        ]
                    },
                    {
                        title: 'Где обучаться?', 
                        items: [
                            '<a href="https://masi.ru/courses/">МИТУ-МАСИ</a> Московский информационно-технологический университет – Московский архитектурно-строительный институт',
                            'Университет Синергия',
                            'КНИТУ Казанский национальный исследовательский технологический университет',
                        ]
                    },
                ]
            },
        ]
    },
    {
        id: 1,
        title: 'Туризм',
        professions: [
            {
                id: 1, 
                name: 'Переводчик с китайского', 
                definition: ' это специалист, обеспечивающий синхронный устный или последовательный перевод с русского на китайский и с китайского на русский. Также он может заниматься письменным переводом литературных текстов, специальных и технических трудов с русского на китайский или с китайского на русский языки. Он создаёт комфортные условия для коммуникации носителей разных языков, помогая вести дружеские и деловые переговоры.', 
                blocks: [
                    {
                        title: 'Переводчик китайского языка должен владеть:', 
                        items: [
                            'техникой перевода',
                            'знанием  языка, иметь представление о теме перевода',
                            'широким кругозором',
                            'знанием истории и культуры Китая и этикета',
                            'основами китайского языка (такие как фонетика и тоны)',
                            'внимательностью и хорошим аналитическим мышлением',
                            'умением сдерживать эмоции',
                            'хорошей памятью',
                            'высокой работоспособностью',
                            'коммуникабельностью',
                        ]
                    },
                    {
                        title: 'В обязанности переводчика китайского языка входит:', 
                        items: [
                            'умение владеть устным синхронным и последовательным переводом',
                            'осуществление письменного перевода текстов, документов, художественной, технической и юридической литературы',
                            'совершенствование навыков владения языком и речью',
                        ]
                    },
                    {
                        title: 'Где обучаться?', 
                        items: [
                            'Работать переводчиком китайского языка может человек, который получил высшее образование, соответствующее области профессиональной деятельности или высшее образование (непрофильное) и дополнительное профессиональное образование – программы профессиональной переподготовки, соответствующие области профессиональной деятельности.',
                            'В Санкт-Петербургском государственном университете (СПбГУ) обучают по программе бакалавриата <a href="https://spbu.ru/postupayushchim/programms/bakalavriat/organizaciya-turistskoy-deyatelnosti-s-uglublennym-izucheniem">«Организация туристской деятельности (с углубленным изучением китайского языка)»</a>',
                        ]
                    },
                ]
            },
            {
                id: 2, 
                name: 'Управляющий отелем', 
                definition: 'верхняя ступень в карьерной лестнице персонала гостиницы. Управляющий отелем должен обеспечить клиентам комфортные условия пребывания, чтобы у них появилось желание вернуться туда снова. Он руководит всеми подразделениями отеля: службами по бронированию мест, приему и размещению гостей, организации телефонной и информационной службы, сервисными подразделениями, рестораном, салонами красоты.', 
                blocks: [
                    {
                        title: 'Управляющий отелем должен знать:', 
                        items: [
                            'основы экономики, управления предприятием, гостиничного менеджмента и маркетинга',
                            'основы психологии и конфликтологии применительно к   обслуживанию клиентов',
                            'правила и методы организации процесса обслуживания клиентов',
                            'структуру управления гостиницей, права и обязанности работников гостиницы',
                            'законы и нормативно-правовые акты, регулирующие вопросы гостиничного обслуживания, правила и нормы охраны труда, техники безопасности, производственной санитарии и противопожарной защиты, порядок содержания жилых и других помещений средств размещения',
                        ]
                    },
                    {
                        title: 'В обязанности управляющего отелем входит:', 
                        items: [
                            'контроль всех сфер деятельности отеля',
                            'планирование бюджета',
                            'контроль издержек',
                            'ведение отчётности по каждому отделу',
                            'разрешение проблемных ситуаций',
                            'проведение ежедневного аудита и профилактического осмотра номеров',
                            'обеспечение отеля квалицированными работниками',
                            'предоставление клиентам информации о предлагаемых услугах',
                            'проведение переговоров с сервисами по бронированию',

                        ]
                    },
                    {
                        title: 'Где обучаться?', 
                        items: [
                            'В Московской Академии туристского и гостинично-ресторанного бизнеса при Правительстве Москвы обучают по программе бакалавриата <a href="http://mosakademtur.ru/main_phtmlpage_programs_pid_3_expand_1.html">«Экономика и управление в туризме и гостиничном хозяйстве»',
                        ]
                    },
                ]
            },
            {
                id: 3, 
                name: 'Управляющий рестораном', 
                definition: 'это специалист, который контролирует повседневную деятельность ресторана, от его профессионализма, личных качеств и опытности зависит успех всего бизнеса.', 
                blocks: [
                    {
                        title: 'Управляющий рестораном должен знать:', 
                        items: [
                            'всё про финансы: как планировать и достигать поставленных задач',
                            'основы психологии и конфликтологии применительно к   обслуживанию клиентов',
                            'правила и методы организации процесса обслуживания клиентов',
                            'структуру управления гостиницей, права и обязанности работников гостиницы',
                            'законы и нормативно-правовые акты, регулирующие вопросы гостиничного обслуживания, правила и нормы охраны труда, техники безопасности, производственной санитарии и противопожарной защиты, порядок содержания жилых и других помещений средств размещения',
                        ]
                    },
                    {
                        title: 'В обязанности управляющего рестораном входит:', 
                    },
                    {
                        title: 'Управление персоналом:', 
                        items: [
                            'поиск сотрудников и обучение персонала',
                            'составление регламента работы и контроль его выполнения',
                            'организует и контролирует регулярность проведения обучения персонала, тренинги, курсы, дегустации и т. д.',
                            'обеспечивает корректное взаимодействие между работниками различных подразделений ресторана',
                            'контролирует  кадровые перестановки, набор и увольнения персонала',
                            'формирует систему материальных взысканий и поощрений',
                        ]
                    },
                    {
                        title: 'Управление финансами:', 
                        items: [
                            'контроль начисления и выплат заработной платы, премий, льгот и прочее;',
                            'подпись счетов, документов',
                            'контроль расходов и прибыли предприятия',
                            'контроль поступлений и переводов денежных средств на счетах',
                            'контролирует ценовую политику ресторана, проведение различных маркетинговых акций',
                            'заключение договоров с поставщиками.',

                        ]
                    },
                    {
                        title: 'Организация и контроль работы всех отделов:', 
                        items: [
                            'кухни, бара',
                            'работников зала ресторана',
                            'бухгалтерии',
                            'руководящих работников предприятия;',
                            'работников технического звена',
                            'службы безопасности или охраны ресторана',
                            'маркетинговой службы',
                        ]
                    },
                    {
                        title: 'Где обучаться?', 
                        items: [
                            'В Санкт-Петербургском государственном экономическом университете обучают по программе бакалавриата <a href="https://unecon.ru/fakultety/fakultet-servisa-turizma-i-gostepriimstva/kafedra-gostinichnogo-i-restorannogo-biznesa/obrazovatelnye-programmy/">«Гостиничное дело»</a> по направлению «Организация и управление в гостиничном и ресторанном бизнесе».',
                        ]
                    },
                ]
            },
        ]
    },
    {
        id: 5,
        title: 'Образование',
        professions: [
            {
                id: 1, 
                name: 'Учитель математики', 
                definition: 'это педагог, осуществляющий воспитательный и образовательный процесс учеников по средствам обучения математике.', 
                blocks: [
                    {
                        title: 'Учитель математики должен знать:', 
                        items: [
                            'основы математической теории и перспективных направлений развития современной математики',
                            'представление о широком спектре приложений математики и знание доступных обучающимся математических элементов этих приложений',
                            'теорию и методику преподавания математики',
                        ]
                    },
                    {
                        title: 'В обязанности учителя математики входит формирование у обучающихся:', 
                        items: [
                            'способности к логическому рассуждению и коммуникации, установки на использование этой способности, на ее ценность',
                            'способности к постижению основ математических моделей реального объекта или процесса, готовности к применению моделирования для построения объектов и процессов, определения или предсказания их свойств',
                            'конкретных знаний, умений и навыков в области математики и информатики',
                            'внутренней (мысленной) модели математической ситуации (включая пространственный образ)',
                            'умения проверять математическое доказательство, приводить опровергающий пример',
                            'умения выделять подзадачи в задаче, перебирать возможные варианты объектов и действий',
                            'умения пользоваться заданной математической моделью, в частности, формулой, геометрической конфигурацией, алгоритмом, оценивать возможный результат моделирования (например — вычисления)',
                            'материальной и информационной образовательной среды, содействующей развитию математических способностей каждого ребенка и реализующей принципы современной педагогики',
                            'умения применять средства информационно-коммуникационных технологий в решении задачи там, где это эффективно',
                            'способности преодолевать интеллектуальные трудности, решать принципиально новые задачи, проявлять уважение к интеллектуальному труду и его результатам',
                            'позитивного отношения со стороны всех обучающихся к интеллектуальным достижениям одноклассников независимо от абсолютного уровня этого достижения',
                            'представлений о полезности знаний математики вне зависимости от избранной профессии или специальности',
                        ]
                    },
                    {
                        title: 'Где обучаться?', 
                        items: [
                            'В Чеченском государственном педагогическом университете обучают по программе бакалавриата <a href="https://chspu.ru/44-03-05-pedagogicheskoe-obrazovanie-napravlennost-profil-matematika-i-informatika/">«Педагогическое образование» (с двумя профилями подготовки), Профили: Математика и Информатика</a>',
                            'В Московском Педагогическом Государственном Университете обучают по программе бакалавриата <a href="http://mpgu.su/ob-mpgu/struktura/faculties/matematicheskiy-fakultet/bakalavriat/pedagogicheskoe-obrazovanie-informatika-i-ekonomika-44-03-05-prikladnoy/">«Педагогическое образование» (с двумя профилями подготовки). Профили: Математика и Дополнительное образование (программирование и робототехника)</a>',
                        ]
                    },
                ]
            },
            {
                id: 2, 
                name: 'Учитель русского языка', 
                definition: '', 
                blocks: [
                    {
                        title: '', 
                    },
                ]
            },
            {
                id: 3, 
                name: 'Учитель физики', 
                definition: 'это педагог, обучающий аудиторию физике в объеме средней и высшей школы. Он ведет занятия в соответствии с учебной программой, дает теоретические знания, проводит практические занятия, демонстрирует опыты. Помимо собственно образовательного процесса, учитель физики прививает ученикам интерес к своему предмету и вообще к получению знаний, ведет внеклассную работу, создает благоприятную учебную атмосферу в учебном заведении.', 
                blocks: [
                    {
                        title: 'Учитель физики должен знать:',
                        items: [
                            'приоритетные направления и перспективы развития педагогической науки',
                            'современные формы и методы обучения и воспитания школьников',
                            'перспективные направления развития современной физики',
                            'рабочую программу и методику обучения физики',
                            'требования к оснащению и оборудованию учебных кабинетов физики',
                            'основы общетеоретических дисциплин в объеме, необходимом для решения педагогических, научно-методических и организационно-управленческих задач',
                            'преподаваемый предмет «Физика» в пределах требований ФГОС и образовательных программ основного и среднего общего образования, его истории и места в мировой культуре и науке'
                        ]
                    },
                    {
                        title: 'Учитель физики должен уметь',
                        items: [
                            'организовать самостоятельную деятельность учащихся, в том числе исследовательскую и проектную',
                            'совместно с детьми проводить анализ учебных и жизненных ситуаций, в которых можно применить знания физики',
                            'использовать информационные источники, следить за последними открытиями в области физики и знакомить с ними учащихся на уроках',
                            'общаться с детьми, признавать их достоинство, понимая и принимая их',
                        ]
                    },
                    {
                        title: 'Где обучаться?',
                        items: [
                            'В Чеченском государственном педагогическом университете обучают по программе бакалавриата «Педагогическое образование» (с двумя профилями подготовки). Профили: <a href="https://chspu.ru/44-03-05-pedagogicheskoe-obrazovanie-s-dvumja-profiljami-napravlennost-profil-fizika-i-jekonomicheskoe-obrazovanie-srok-obuchenija-5-let/">Физика и экономическое образование</a>.',
                        ]
                    },
                ]
            },
            {
                id: 4, 
                name: 'Учитель английского языка', 
                definition: 'это педагог, обучающий языковым знаниям, умениям и навыкам в области фонетики, лексики и грамматики английского языка.', 
                blocks: [
                    {
                        title: 'Учитель английского языка должен',
                        items: [
                            'знать английский язык',
                            'владеть коммуникативной методикой преподавания',
                            'повышать свой профессиональный уровень',
                            'использовать современные формы и методы обучения и воспитания школьников',
                            'ориентироваться в психологии, уметь построить доверительные отношения с учениками',
                            'знать методики планирования и проведения уроков английского языка для учеников разных возрастных групп',
                        ]
                    },
                    {
                        title: 'В обязанности учителя английского языка входит',
                        items: [
                            'уметь доступно и понятно преподносить материал',
                            'использовать современные обучающие и информационные технологии',
                            'создавать позитивную обстановку на уроках',
                            'использовать современные формы и методы обучения и воспитания школьников',
                            'ориентироваться в психологии, уметь построить доверительные отношения с учениками',
                            'знать методики планирования и проведения уроков английского языка для учеников разных возрастных групп',
                        ]
                    },
                    {
                        title: 'Что отличает учителя английского от классного учителя английского? Конечно, желание учиться и развиваться. Крутые учителя имеют одну схожую черту: они умеют и любят учиться сами, а значит способны привить эту любовь и к своим ученикам.'
                    },
                    {
                        title: 'Где учиться?',
                        items: [
                            'Чтобы претендовать на должность учителя английского в школе или в вузе, необходим диплом о высшем профильном образовании. Вузы, готовящие педагогов, есть в каждом регионе России. При поступлении учитываются результаты единых госэкзаменов по русскому и иностранному языкам и обществознанию. Учиться придется не менее пяти лет, есть возможность выбрать очную, заочную или очно-заочную форму обучения.',
                            'На факультете иностранных языков Чеченского государственного университета им. А.А. Кадырова имеется программа бакалавриата <a href="https://www.chesu.ru/major?p=58">«Лингвистика»</a>',
                        ]
                    },
                ]
            },
        ]
    },
    {
        id: 5,
        title: 'Сельское хозяйство',
        professions: [
            {
                id: 1, 
                name: 'Ветеринар', 
                definition: 'это специалист, занимающийся лечением и профилактикой заболеваний животных. В его обязанности входит осмотр, оказание медицинской помощи, вакцинация, чипирование, проведение различных процедур и операций, а также консультирование клиентов по уходу и содержанию животных.', 
                blocks: [
                    {
                        title: 'Ветеринар должен:', 
                        items: [
                            'понимать физиологию и анатомию животных самых разнообразных видов',
                            'уметь проводить различные процедуры и манипуляции — перевязки, операции, вскрытия',
                            'знать болезни животных и методики обследования, лечения этих заболеваний',
                            'разбираться в современных лекарствах, медицинском оборудовании',
                            'иметь хорошую память, аналитическое мышление, крепкие нервы, быструю реакцию',
                            'должны отсутствовать брезгливость, излишняя эмпатия',
                        ]
                    },
                    {
                        title: 'Чем занимается ветеринар:', 
                        items: [
                            'осмотром животного, слушает жалобы владельцев, чтобы поставить диагноз',
                            'берет анализы, делает УЗИ',
                            'назначает и проводит медикаментозное лечение, при необходимости направляет на операцию',
                            'контролирует состояние четверолапого пациента на всех этапах лечения; ',
                            'может производить разные процедуры (стерилизация, кастрация, акушерство)',
                        ]
                    },
                    {
                        title: 'Где обучаться?', 
                        items: [
                            'В Агротехнологическом институте Чеченского государственного университета им. А.А. Кадырова имеется программа специалитета <a href="https://www.chesu.ru/major?p=50">«Ветеринария»',
                        ]
                    },
                ]
            },
            {
                id: 2, 
                name: 'Зоотехник', 
                definition: 'это специалист, занимающийся разведением, содержанием, кормлением и эксплуатацией животных сельскохозяйственного назначения. В его задачи входит получение максимальной экономической отдачи от разведения того или иного вида животных. Зоотехник специализируется на сельскохозяйственных животных, формируя кормовую базу, создавая оптимальные условия для их содержания, лечения, разведения. Он занимается селекцией, контролирует качество и этапы производства продуктов из животного сырья.', 
                blocks: [
                    {
                        title: 'Зоотехник должен знать:', 
                        items: [
                            'анатомию и физиологию животных',
                            'особенности содержания, размножения и кормления различных видов животных',
                            'основы гигиены животных, особенности их размещения и транспортировки',
                            'способы разведения пород и основ племенного дела',
                            'способы улучшения структуры стада и роста молодняка',
                            'способы повышения продуктивности поголовья',
                            'методы контроля качества получаемой продукции животноводства',
                        ]
                    },
                    {
                        title: 'Обязанности зоотехника:', 
                        items: [
                            'соблюдение рекомендаций по кормлению и рациону животных',
                            'раздача кормов и других расходных материалов, ручная или механизированная чистка мест содержания',
                            'периодический осмотр животных, выявление больных и дефектных особей',
                            'сбор продукции животноводства, контроль ее качества',
                            'проведение профилактических и противоэпидемиологических мероприятий с поголовьем, вакцинирование и лечение животных',
                            'составление графиков выгула и выпаса поголовья',
                            'ведение документации в соответствии с должностными обязанностями',
                        ]
                    },
                    {
                        title: 'Где обучаться?', 
                        items: [
                            'В Агротехнологическом институте Чеченского государственного университета им. А.А. Кадырова имеется программа бакалавриата <a href="https://www.chesu.ru/major?p=85">«Зоотехния»',

                        ]
                    },
                ]
            },
        ]
    },
]