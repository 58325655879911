import { createRouter, createWebHistory } from "vue-router";

import HomeView from "@/views/HomePage/HomeView.vue";
import ArticleView from "@/views/Article/ArticleView.vue";
import AboutInformatioView from "@/views/AboutInformatio/AboutInformatioView.vue";
import AboutProjectView from "@/views/AboutProject/AboutProjectView.vue";
import ProfessionsInfoView from "@/views/ProfessionsChR/ProfessionsInfoView.vue";
import UsefulResourcesView from "@/views/UsefulResources/UsefulResourcesView.vue";
import ToTeachersView from "@/views/ToTeachers/ToTeachersView.vue";
import SpecialistsView from "@/views/Specialists/SpecialistsView.vue";
import ToParentsView from "@/views/ToParents/ToParentsView.vue";

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
    meta: {
      layout: "MainLayout",
    },
  },
  {
    path: "/article/:id",
    name: "article",
    component: ArticleView,
    meta: {
      layout: "ArticleLayout",
    },
  },
  {
    path: "/about-informatio",
    name: "about-informatio",
    component: AboutInformatioView,
    meta: {
      layout: "MainLayout",
    },
  },
  {
    path: "/about-project",
    name: "about-project",
    component: AboutProjectView,
    meta: {
      layout: "MainLayout",
    },
  },
  {
    path: "/professions",
    name: "professions",
    component: ProfessionsInfoView,
    meta: {
      layout: "MainLayout",
    },
  },
  /* {
    path: "/institutions",
    name: "institutions",
    component: InstitutionsView,
    meta: {
      layout: "StudentsLayout",
    },
  }, */
  {
    path: "/useful",
    name: "useful",
    component: UsefulResourcesView,
    meta: {
      layout: "MainLayout",
    },
  },
  {
    path: "/toteachers",
    name: "toteachers",
    component: ToTeachersView,
    meta: {
      layout: "MainLayout",
    },
  },
  {
    path: "/specialists",
    name: "specialists",
    component: SpecialistsView,
    meta: {
      layout: "MainLayout",
    },
  },
  {
    path: "/toparents",
    name: "toparents",
    component: ToParentsView,
    meta: {
      layout: "MainLayout",
    },
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  linkActiveClass: 'active',
  linkExactActiveClass: 'active',
  scrollBehavior() {
    return { top: 0 }
  },
});

export default router;
