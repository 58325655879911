<template>
    <li class="article-item">
        <img :src="article.image" alt="">
        <p class="article-logo">INformatio</p>
        <div class="article-description">
            <div class="article-description__info">
                <p class="article-hashtag">#{{article.tags[0]}}</p>
                <p class="article-views">
                    <img src="@/assets/images/icons/eye.svg" class="views-icon">
                    <span>{{article.article_views}}</span>
                </p>
            </div>
            <h3 class="article-title"><router-link :to="('/article/' + article.id)">{{article.title}}</router-link></h3>
            <p class="article-anons">{{article.annonce}}</p>
        </div>
    </li>
</template>

<script>
export default {
    props: {
        article: {
            type: Object,
            required: true
        }
    }
}
</script>

<style lang="scss" scoped>
.article-item{
    width: 100%;
    position: relative;
}

.article-item img{
    width: 100%;
    height: 223px;
    object-fit: cover;
    border-radius: 15px;

    &.views-icon{
        width: 48px;
        height: auto;
    }
}

.article-anons{
    font-family: "Manrope Medium", "Arial", sans-serif;
    font-size: 14px;
    letter-spacing: -0.02em;
    margin: 0;
    color: #81859C;
    line-height: 19px;
}

.article-description__info{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.article-logo{
    position: absolute;
    left: 18px;
    top: 18px;
    background: #14BEB6;
    border-radius: 4px;
    padding: 5px;
    color: #fff;
    font-family: "Manrope SemiBold", "Arial", sans-serif;
    font-size: 12px;
    line-height: 11px;
    letter-spacing: -0.02em;
    margin: 0;
}

.article-hashtag{
    font-family: "Manrope", "Arial", sans-serif;
    font-size: 20px;
    letter-spacing: -0.02em;
    color: #14BEB6;
    margin: 0 24px 0 0;
}

.article-views{
    font-family: "Manrope", "Arial", sans-serif;
    color: #81859C;
}

.articles-title{
    margin: 0;
}

.article-title a{
    font-family: "Manrope Bold", "Arial", sans-serif;
    font-size: 2rem;
    line-height: 44px;
    letter-spacing: -0.02em;
    display: inline-block;
    margin: 24px 0;
    text-decoration: none;
    color: #000;
}

.article-views {
    display: flex;
    align-items: center;

    span{
        font-size: 1rem;
    }
}


@media(max-width: 1023px) {

    .article-hashtag,
    .article-views {
        font-size: 14px;
    }
}


@media(max-width: 767px) {

    .article-hashtag,
    .article-views {
        font-size: 11px;
    }

    .article-text ul {
        list-style: url("@/assets/images/icons/list-min-icon.svg");
    }

    .article-title a{ 
        font-size: 1.3rem;
        line-height: 30px;
    }
}

</style>