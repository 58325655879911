<template>
  <div>
        <section class="about-start">
            <div class="wrapper">
                <h2 class="about-start__title">
                    С чего всё <span>началось? <img class="title-vector" src="@/assets/images/icons/about-vector.svg" alt=""></span> 
                </h2>

                <img class="section-image" src="@/assets/images/about-start.png" alt="">

                <p>Нашей Фаризе как-то в соцсетях написали: <b>«Я в выпускном классе, но не знаю куда поступать. Что делать?»</b> На что она ответила:</p>
                <ul>
                    <li><p>Дать себе год на размышления и поиск «своего дела».</p></li>
                    <li>
                        <p>
                            Обратиться к специалистам из разных областей, 
                            чтобы они не только рассказали о профессии, 
                            но и показали какие реальные задачи нужно выполнять в рабочем процессе, 
                            рассказали о плюсах, минусах и личностных качествах, 
                            которые необходимы в конкретной профессии. Рассказали о своём опыте.
                        </p> 
                    </li>
                </ul>

                <p>
                    После этого Фариза стала размышлять: «разве школьник сможет отыскать 
                    представителей нескольких профессий и убедить их уделить ему столько времени, 
                    даже при помощи взрослых? Профессий то много. 
                    И ещё эта проблема есть не только конкретно у этого школьника/школьницы. 
                    А что если нам собрать детей, пригласить спикеров из самых разных сфер и организовать 
                    площадку по профориентации для отобранных школьников? Дать им реальные кейсы, 
                    которые они решат с помощью специалистов. Организовать выезды непосредственно 
                    на рабочие места?»
                </p>
                <p class="about-start__end">
                    Из этих вопросов и возникла идея нашего проекта «Специалист выпускнику», 
                    которую мы оформили и подали в Фонд президентских грантов. Проект 
                    выиграл, и мы организовали проектную смену!
                </p>
                </div>
        </section>

        <section class="about-project">
            <div class="wrapper">
                <h2 class="about-project__title">О проектной <span>смене<img class="title-vector" src="@/assets/images/icons/about-vector.svg" alt=""></span></h2>
                <p>Первой частью проекта стала проектная смена в Центре одаренных детей «Ахмат», 
                    где 55 учащихся на протяжении 3-х недель общались с более 60-тью специалистами 
                    из самых различных профессий, участвовали в 5 выездных экскурсиях, 
                    примеряли на себя роль юристов в судебных дебатах, работая совместно 
                    со студентами Юридической клиники ЧГУ, обучались техническим и инженерным навыкам. </p>
                <iframe  src="https://www.youtube.com/embed/LbNsEuTx6lg" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                <p>Проект "Профориентационная смена "Специалист выпускнику", поддержанный Фондом президентских грантов</p>
                <iframe width="970" height="490" src="https://www.youtube.com/embed/hHiKVPbQ0DE" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                <p>Сюжет ЧГТРК "Грозный" о проекте ИН-форматио "Профориентационная компания "Специалист выпускнику"</p>
                <img class="section-image" src="@/assets/images/about-project.png" alt="">
            </div>
        </section>

        <section class="about-platform">
            <div class="wrapper">
                <h2 class="about-platform__title">
                    Идея с <span>платформой<img class="title-vector" src="@/assets/images/icons/about-vector.svg" alt=""></span> 
                </h2>
                <p>Создание платформы вошло во вторую часть реализации проекта 
                    «Специалист выпускнику». Мы понимали, что на смену попадут только единицы,
                        а школьников с проблемой выбора профессии тысячи.
                </p> 
                <p>
                    Так мы решили, что сделаем платформу, где школьники смогут найти для себя 
                    ответы на вопросы непосредственно от специалистов той или иной профессии. 
                    Большое количество профессионалов с радостью согласились поделиться своим 
                    опытом и мыслями с учениками, а мы в свою очередь оформили их знания в 
                    полезные статьи и видеоматериалы, и теперь делимся ими, размещая на этом сайте. 
                </p>

                <img class="section-image"  src="@/assets/images/about-platform.png" alt="">
            </div>
        </section>
        <section class="project-team">
            <div class="wrapper">
                <h2 class="project-team__title">
                    Команда <span>проекта<img class="title-vector" src="@/assets/images/icons/about-vector.svg" alt=""></span> 
                </h2>
                <ul class="team-list">
                    <li>
                        <h3>Хазбиев Тимур</h3>
                        <p>Генеральный директор АНО «НКЦ «ИН-форматио»</p>
                    </li>
                    <li>
                        <h3>Юсупова Адат</h3>
                        <p>Преподаватель Юридического факультета ЧГУ им. А.А. Кадырова</p>
                    </li>
                    <li>
                        <h3>Каимова Фариза</h3>
                        <p>Frontend разработчик в IT-компании Thesis. <br>Frontend разработчик и преподаватель в международной IT-компании «Expovision»</p>
                    </li>
                </ul>
            </div>
        </section>
    </div>
</template>

<script>
export default {

}
</script>

<style scoped src='./style.css'></style>