<template>
    <section class="resources">
        <div class="wrapper">
            <h1 class="resurces-title">Полезные ресурсы по <span>профориентации: <img class="title-vector" src="@/assets/images/icons/resources-vector.svg" alt=""></span></h1>
            <img class="resources-img" src="@/assets/images/resources.png" alt="">
            <ul class="resources-list">
                <li class="resources-item"><a href=" https://www.profguide.io/test/klimov.html">Тест на профориентацию по методике Е.А. Климова </a></li>
                <li class="resources-item"><a href="https://psytests.org/profession/hollandA-run.html">Тест профессионального личностного типа Голланда </a></li>
                <li class="resources-item"><a href="https://psytests.org/personal/potemkina.html">Социально-психологические установки личности Потемкиной</a></li>
                <li class="resources-item"><a href="https://proforientatsia.ru/test/opredelenie-professionalnyh-sklonnostej/">Определение профессиональных склонностей Л. Йовайши </a></li>
                <li class="resources-item"><a href="https://testometrika.com/personality-and-temper/questionnaire-eysenck-pen/">Тест Айзенка на определение темперамента человека</a></li>
                <li class="resources-item"><a href="https://new.atlas100.ru/">Атлас новых профессий </a></li>
                <li class="resources-item"><a href="https://bvbinfo.ru/">Билет в будущее </a></li>
            </ul>
        </div> 
    </section>
</template>

<script>
export default {

}
</script>

<style scoped src='./style.css'></style>