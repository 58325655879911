<template>
    <ul class="main-nav__items">
        <li class="main-nav__item"><router-link to="/">Главная</router-link></li>
        <li class="main-nav__item nav-item__submenu">
            <router-link to="/about-project">О&nbsp;нас <span class="item-triangle"></span></router-link>
            <div class="submenu">
                <div class="submenu-inner">
                    <ul>
                        <li><router-link to="/about-informatio">Об ИН-форматио </router-link></li>
                        <li><router-link to="/about-project">О проекте «Специалист выпускнику»</router-link></li>
                    </ul>
                    <div class="triangle"></div>
                </div>
            </div>
        </li>
        <li class="main-nav__item nav-item__submenu">
            <router-link to="/professions">Учащимся <span class="item-triangle"></span></router-link>
            <div class="submenu">
                <div class="submenu-inner">
                    <ul>
                        <li><router-link to="/professions">Информация об актуальных профессиях в ЧР</router-link></li>
                        <li><router-link to="/useful">Полезные ресурсы</router-link></li>
                    </ul>
                    <div class="triangle"></div>
                </div>
            </div>
        </li>
        <li class="main-nav__item"><router-link to="/toparents">Родителям</router-link></li>
        <li class="main-nav__item nav-item__submenu">
            <router-link to="/toteachers">Педагогам <span class="item-triangle"></span></router-link>
            <div class="submenu">
                <div class="submenu-inner">
                    <ul>
                        <!-- временный путь -->
                        <li><router-link to="/specialists">Контакты специалистов</router-link></li>
                        <li><router-link to="/toteachers">Рекомендации</router-link></li>
                    </ul>
                    <div class="triangle"></div>
                </div>
            </div>
        </li>
    </ul>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
.main-nav__items {
    list-style: none;
    display: flex;
    justify-content: flex-end;
    padding: 0;
}

.main-nav__item {
    padding: 0;
    margin: 0;
    position: relative;
}

.main-nav__item a {
    display: flex;
    align-items: center;
    text-decoration: none;
    font-family: 'Manrope Medium';
    color: #000;
    font-size: 20px;
    margin-left: 32px;
    margin-bottom: 13px;
}


.main-nav__item a:hover,
.main-nav__item a:active {
    color: #14BEB6;
}

a.active {
    color: #14BEB6;
}



/* submenu */

.nav-item__submenu a:hover,
.nav-item__submenu a:active {
    color: #14BEB6;
}

.submenu {
    position: absolute;
    z-index: 1000;
    display: none;
    top: 20px;
    right: -20px;
    padding-top: 20px;
}

.nav-item__submenu:hover .submenu {
    display: block;
}

.submenu:hover {
    display: block;
}

.submenu-inner {
    border: 1px solid rgba(0, 0, 0, 0.1);
    background: #1D262D;
    color: #b9c1c5;
    position: relative;
    border-radius: 10px;
}

.submenu-inner ul {
    list-style: none;
    margin: 0;
    min-width: 420px;
    padding: 15px;
}

.submenu-inner li {
    max-width: 340px;
    padding: 0 15px;
}

.submenu-inner a {
    text-decoration: none;
    font-size: 0.937em;
    color: #545e69;
    padding: 7px 15px 6px 15px;
    display: block;
    margin-left: 0;
}

.submenu-inner a:hover,
.submenu-inner a:active {
    color: #14BEB6;
}

.triangle {
    width: 12px;
    height: 12px;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    border-left: 1px solid rgba(0, 0, 0, 0.1);
    background-color: #14BEB6;
    position: absolute;
    transform: rotate(90deg);
    top: -6px;
    right: 18px;
}

.item-triangle{
    margin-left: 15px;
    display: inline-block;
    width: 0;
    height: 0;
    border-bottom: 10px solid #14BEB6;;
    border-left: 10px solid transparent;
    transform: rotate(45deg);
}

@media(max-width: 935px) { 
    .submenu {
        position: static;
        display: block;
        padding-top: 0;
        margin: 5px 0 14px;

        &-inner {
            ul{
                padding: 0;
                min-width: 100%;
            }

            li{
                padding: 0 15px;
            }

            a{
                font-size: .9rem;
            }
        }
    }
}
</style>