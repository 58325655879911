<template>
    <section class="professions">
        <div class="wrapper">
            <h1 class="professions-title">
                Перечень приоритетных профессий в&nbsp;Чеченской&nbsp;<span>Республике<img class="title-vector" src="@/assets/images/icons/students-vector.svg" alt=""></span> 
            </h1>
            <ProfessionsBlock
                v-for="(sphere, index) in spheres"
                :key="index"
                :title="sphere.title"
                :professions="sphere.professions"
            />
        </div>
    </section>
</template>

<script>
import spheres from '@/data/spheres'
import ProfessionsBlock from '@/components/ProfessionsBlock.vue'

export default {
    components: { ProfessionsBlock },
    data() {
        return {
            spheres
        }
    }
}
</script>

<style scoped src='./style.css'></style>