<template>
  <div>
        <header @click="openDescription">
            <h4>{{profession.name}}</h4>
            <div class="button-open" :class="{'button-open__active': isOpened}">
                <div><span></span><span></span></div>
            </div>
        </header>
        <Transition>
            <div class="profession-description" v-if="isOpened">
                <div class="description-block">
                    <p>{{profession.name}} - {{profession.definition}}</p>
                    <div
                        v-for="(block, index) in profession.blocks"
                        :key="index"
                    >
                        <h5>{{block.title}}</h5>
                        <ul v-if="block.items">
                            <li
                                v-for="(item, index) in block.items"
                                :key="index"
                                v-html="item"
                            ></li>
                        </ul>
                    </div>
                </div>
            </div>
        </Transition>
    </div>
</template>

<script>
export default {
    props: {
        profession: Object
    },
    data() {
        return {
            isOpened: false
        }
    },
    methods: {
        openDescription() {
            this.isOpened = !this.isOpened
        }
    }
}
</script>

<style scoped>
.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}

header{
    cursor: pointer;
}
.button-open{
    width: 29px;
    height: 29px;
    border: 1px solid #14BEB6;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background-color 1s;
    cursor: pointer;
}

.button-open div{
    position: relative;
    width: 10px;
    height: 10px;
    transition: transform 1s;
}


.button-open span{
    position: absolute;
    background-color: #14BEB6;
    width: 10px;
    height: 1px;
    border-radius: 5px;
    bottom: 50%;
}

.button-open span:first-child{
    transform: rotate(90deg);

}

.button-open__active{
    background-color: #14BEB6;
}

.button-open__active span{
    background-color: #fff;
}

.button-open__active div{
    transform: rotate(-45deg);
}

.profession-description{
    transition: .3s;
}
.profession-description__list{
    list-style: none;
    margin: 0;
    padding: 0;
}

.profession-description a{
    font-size: 16px;
    color: #007DFF;
    text-decoration: none;
}

.profession-description__list > div{
    margin-top: 32px;
}


ul{
    font-size: 16px;
    line-height: 24px;
    list-style: disc;
    padding-left: 38px;
}

li{
    margin: 4px;
}

h5{
    font-size: 16px;
    font-family: "Manrope SemiBold";
    line-height: 24px;
    margin: 30px 0 16px 0;
}

@media(max-width: 768px) {
    .profession-description a{
        font-size: 12px;
    }

   /*  .profession-description__active{
        max-height: 2000px;
    }
 */
    h5{
        font-size: 12px;
        line-height: 17px;
    }

    ul{
        font-size: 12px;
        line-height: 17px;
        padding-left: 27px;
    }
}


</style>