<template>
  <HeaderComponent />
  <component v-if="layout" :is="layout"></component>
  <FooterComponent />
</template>

<script>
import { computed, onMounted } from 'vue'
import { useRoute } from 'vue-router'

import HeaderComponent from '@/layouts/parts/HeaderComponent.vue'
import MainLayout from '@/layouts/MainLayout.vue'
import ArticleLayout from '@/layouts/ArticleLayout.vue'
import FooterComponent from './layouts/parts/FooterComponent.vue'
import { useStore } from 'vuex'

export default {
  components: {
    HeaderComponent,
    MainLayout,
    ArticleLayout,
    FooterComponent
},
  setup() {
    const route = useRoute();
    const store = useStore();
    const layout = computed(() => route.meta.layout)

    onMounted(() =>  {
      store.dispatch('fetchArticles')
    })

    return {
      layout
    }
  },
}
</script>
<style lang="scss">

@import '@/assets/styles/css/normalize.css';
@import '@/assets/styles/css/reset.css';
@import '@/assets/styles/css/styles.css';

</style>
