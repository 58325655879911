<template>
    <section class="aboutInformatio">
        <div class="wrapper">
            <h1 class="aboutInformatio-title"><span>ИН-форматио<img class="title-vector" src="@/assets/images/icons/aboutIn-vector.svg" alt=""></span></h1>
            <img src="@/assets/images/aboutInFormatio.jpeg" alt="" class="aboutInformatio-img">
            <div class="aboutInformatio-text">
                <p>Начиная с 2015 года ИН-форматио активно реализует социальные и образовательные проекты: </p>
                <ul class="aboutInformatio-list">
                    <li>Создание информационно-технологического центра для школьников в высокогорных районах республики «IT-центр Беной»</li>
                    <li>Издание первой в своем роде для Чеченской Республики книги «20 обычных чеченцев», рассказывающей истории 20 успешных молодых чеченцев, которые добились значительных достижений в разных сферах</li>
                    <li>Реализация проекта «Книжная полка педагога», цель которого донести до учителей Чеченской Республики лучшие педагогические идеи прошлого и современности для их последующего внедрения в наших школах</li>
                    <li>Запуск цикла видеороликов «Юрист в сфере образования», в которых лучшие юристы и управленцы в сфере образования объясняют разнообразные правовые тонкости и отвечают на вопросы учителей и директоров школ</li>
                    <li>Открытие площадки для проведения Олимпиада НИУ ВШЭ для студентов и выпускников вузов в Грозном</li>
                    <li>Проведение курсов повышения квалификации «Aspire to English» для учителей английского языка из Чеченской Республики и соседних регионов</li>
                    <li>Реализация проекта «Центр практической подготовки социально-ориентированных юристов», в рамках которого студенты получили возможность принять участие в интерактивных тренингах, посвященных практическим навыкам студентов, посетить реальные судебные заседания вместе с наставниками, практиковать право по реальным делам под руководством кураторов и принять участие во многих других мероприятиях проекта, а социально-незащищенные категории населения получили доступ к качественной бесплатной юридической помощи</li>
                    <li>Проведение курсов повышения квалификации «Aspire to English» для учителей английского языка из Чеченской Республики и соседних регионов</li>
                    <li>Организация ежегодного конкурса-эссе по чеченскому языку «Ненан меттано йовхо»</li>
                    <li>Реализация множества других мероприятий и конкурсов.</li>
                </ul>
            </div>
            <div class="inFormatio-projects">
                <h3>Проекты</h3>
                <ul class="project-list">
                    <li><a href="https://in-formatio.ru/it-czentr-benoj-2">Книжная полка педагога</a></li>
                    <li><a href="https://in-formatio.ru/it-czentr-benoj-4">Вышка в Чеченской Республике</a></li>
                    <li><a href="https://in-formatio.ru/it-czentr-benoj-3">Рубрика "Юрист в сфере образования"</a></li>
                    <li><a href="https://in-formatio.ru/kursyi-anglijskogo-%E2%80%93-aspire-to-english ">Курсы английского – Aspire to English</a></li>
                </ul>
            </div>
        </div>   
    </section>
</template>

<script>

export default {

}
</script>

<style scoped src='./style.css'></style>